<template>
  <div class="row">
    <div class="col-4">
      <div class="row">
      <div class="col">
        
      </div>
      </div>
    </div>
    <div class="col-7">
      <div class="about">
        <h1>
          Requilend is a fast growing merchant service and lending provider located in Dallas, Texas.
        </h1>
        <h3>
          Founded in 2021, after a decade of working in the financial sector and dealing with the
          hassles that come with merchant brokers and their providers, our
          founders pondered why there wasn't an easier and faster way to obtain
          merchant services and lending.
        </h3>
      </div>
    </div>
  </div>
</template>

